import React, { useState } from "react";
import { auth } from "../firebase";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendEmailVerification,
} from "firebase/auth";
import { Modal } from "react-bootstrap";
import "./Signup.css";

const Signup = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [date_of_birth, setDateOfBirth] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const googleProvider = new GoogleAuthProvider();

  const handleChange = (setter) => (event) => setter(event.target.value);
  const handleTermsChange = (event) => {
    setTermsAccepted(event.target.checked);
  };

  const handleGoogleSignup = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const firestore = getFirestore();

      // Store user details in the 'users' collection
      await setDoc(doc(collection(firestore, "users"), user.uid), {
        firstName: user.displayName.split(" ")[0],
        lastName: user.displayName.split(" ")[1] || "",
        email: user.email,
        date_of_birth: "", // Can prompt for DOB if needed
        uid: user.uid,
        verified: true,
        provider: "google",
      });

      alert("Signed in successfully with Google");
      window.location.href = "/hotels";
    } catch (error) {
      console.error("Google Signup Error: ", error);
      alert("Error during Google sign-in: " + error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !firstName ||
      !lastName ||
      !email ||
      !password ||
      !confirmPassword ||
      !date_of_birth
    ) {
      alert("Please fill all the fields");
      return;
    }

    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      alert("Please enter a valid email address");
      return;
    }

    if (password.length < 6) {
      alert("Password should be at least 6 characters long");
      return;
    }

    // Check password length and character requirements
    const passwordPattern = /^[a-zA-Z0-9]{6,}$/; // At least 6 alphanumeric characters
    if (!passwordPattern.test(password)) {
      alert(
        "Password must be at least 6 alphanumeric characters long and contain no special characters"
      );
      return;
    }

    const dob = new Date(date_of_birth);
    const today = new Date();
    const age = today.getFullYear() - dob.getFullYear();
    const monthDifference = today.getMonth() - dob.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < dob.getDate())
    ) {
      age--;
    }
    if (age < 15) {
      alert("You must be at least 15 years old to register");
      return;
    }
    if (!termsAccepted) {
      alert("You must agree to the Terms of Service and Privacy Policy");
      return;
    }

    try {
      // Create user with email and password
      const userCredentials = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredentials.user;

      // Send email verification
      await sendEmailVerification(user);

      // Store user details in Firestore
      const firestore = getFirestore();
      await setDoc(doc(collection(firestore, "users"), user.uid), {
        firstName: firstName,
        lastName: lastName,
        email: email,
        date_of_birth: date_of_birth,
        uid: user.uid,
        verified: false, // Initially not verified until email is confirmed
      });

      alert(
        "Verification email sent to your email address. Please verify your email before logging in."
      );
      window.location.href = "/login";
    } catch (error) {
      console.error(error);
      alert("Error creating account: " + error.message);
    }
  };

  const handleBackToSignIn = (event) => {
    event.preventDefault();
    window.location.href = "/login";
  };

  return (
    <div className="signup">
      <div className="signup_image">
        <img src="./signup.png" alt="signup" />
      </div>
      <div className="signup_form">
        <img className="logo" src="./logo.png" alt="logo" />
        <h2>Create account</h2>
        <p className="subtitle">
          Let’s get you all set up so you can access your personal account.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="signup_form_row">
            <div className="signup_form_column">
              <label htmlFor="fname">First name</label>
              <input
                className="i"
                type="text"
                value={firstName}
                onChange={handleChange(setFirstName)}
              />
            </div>
            <div className="signup_form_column">
              <label htmlFor="lname">Last name</label>
              <input
                className="i"
                type="text"
                value={lastName}
                onChange={handleChange(setLastName)}
              />
            </div>
          </div>
          <div className="signup_form_row">
            <div className="signup_form_column">
              <label htmlFor="email">Email</label>
              <input
                className="i"
                type="text"
                value={email}
                onChange={handleChange(setEmail)}
              />
            </div>
            <div className="signup_form_column">
              <label htmlFor="date_of_birth">Date of birth</label>
              <input
                className="i"
                type="date"
                value={date_of_birth}
                onChange={handleChange(setDateOfBirth)}
              />
            </div>
          </div>
          <div className="signup_form_row">
            <div className="signup_form_column">
              <label htmlFor="confirm_password">Confirm password</label>
              <input
                className="i"
                type="password"
                value={confirmPassword}
                onChange={handleChange(setConfirmPassword)}
              />
            </div>
            <div className="signup_form_column">
              <label htmlFor="password">Password</label>
              <input
                className="i"
                type="password"
                value={password}
                onChange={handleChange(setPassword)}
              />
            </div>
          </div>
          <div className="check1">
            <input
              className="check"
              type="checkbox"
              id="remember"
              name="remember"
              value="remember"
            />
            <label htmlFor="remember">Remember me</label>
            <br></br>
            <input
              className="check"
              type="checkbox"
              id="terms"
              name="terms"
              value="terms"
              checked={termsAccepted}
              onChange={handleTermsChange}
            />
            <label htmlFor="terms">
              I agree to the <a href="#">Terms of Service</a> and{" "}
              <a href="#">Privacy Policy</a>
            </label>
          </div>
          <button type="submit">Create Account</button>
          <p className="backtosignin" onClick={handleBackToSignIn}>
            Already have an account? <a href="#">Sign in</a>
          </p>
        </form>
        <div className="signup_form_social">
          <p>Or sign up with</p>
          <div className="signup_form_social_icons">
            <img
              src="./google.png"
              alt="google"
              onClick={handleGoogleSignup}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
