import React, { useState } from "react";
import { auth } from "../firebase";
import { sendPasswordResetEmail } from 'firebase/auth';
import './ForgetPassword.css';

const Forgot = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      if (!email) {
        alert("Please enter your email");
        return;
      }

      await sendPasswordResetEmail(auth, email);
      setEmailSent(true);
      alert("Password reset email sent. Please check your inbox.");
    } catch (error) {
      alert("Error sending password reset email: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleBackToSignIn = (event) => {
    event.preventDefault();
    window.location.href = "/";
  };

  return (
    <div className="forgot">
      <div className="forgot_image">
        <img src="./forgot.png" alt="forgot" />
      </div>
      <div className="forgot_form">
        <img className="logo" src="./logo.png" alt="logo" />
        <h2>Forgot your password?</h2>
        <p>
          Don’t worry, happens to all of us. Enter your email below to recover
          your password. We’ll send you a link to reset your password.
        </p>
        <p>Email</p>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter your email"
          />
          <button type="submit" disabled={loading}>
            {loading ? "Sending..." : "Send"}
          </button>
        </form>
        <p className="backtosignin" onClick={handleBackToSignIn}>
          Back to <a href="#">Sign in</a>
        </p>
      </div>
    </div>
  );
};

export default Forgot;
